$(document).ready(function () {
    // Header Sticky
    let lastScroll = 0;
    const header = document.querySelector('header');

    window.addEventListener('scroll', () => {
        const scroll = window.pageYOffset;
        if (lastScroll - scroll > 0 && scroll > 200) {
            header.classList.add('sticky-menu');
        } else {
            header.classList.remove('sticky-menu');
        }
        if (lastScroll - scroll < 0 && scroll > 200) {
            header.classList.add('hidden-top');
        } else {
            header.classList.remove('hidden-top');
        }
        lastScroll = scroll;
    });

    $('.burger-menu').click(function (e) {
        e.preventDefault();
        $('body').toggleClass('menu-is-open');
        $(this).toggleClass('active');
        $('.megamenu-style-dev').toggleClass('active');
        $('.megamenu-wrapper').toggleClass('so-megamenu-active');
    });

    // Functions
    const dynamicCSS = (selector, target, property) => {
        let value = target.offsetHeight;
        selector.style.setProperty(property, `${value}px`);
        window.addEventListener('resize', () => {
            value = target.offsetHeight;
            selector.style.setProperty(property, `${value}px`);
        });
    };

    dynamicCSS( document.querySelector('body'), document.querySelector('header'), '--headerHeight');


    let screenHeader = window.innerHeight;
    document.querySelector('body').style.setProperty('--screenHeader', screenHeader + 'px');
    window.addEventListener('resize', () => {
        const screenHeader = window.innerHeight;
        document.querySelector('body').style.setProperty('--screenHeader', screenHeader + 'px');
    });

});

