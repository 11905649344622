$(document).ready(function () {
    // footer
    if ($(window).width() <= 991) {
        $('.column-block.mobile-toggle > .column-data').hide();
        $('.column-block.mobile-toggle > .column-head').click(function () {

            if ($(this).parent().hasClass('active')){
                $(this).parent().removeClass('active');
                $(this).parent().find('.column-data').slideUp();
            } else {
                $('.column-block.mobile-toggle').removeClass('active');
                $('.column-block.mobile-toggle > .column-data').slideUp();
                $(this).parent().addClass('active');
                $(this).parent().find('.column-data').slideDown();
            }
        });
    }
});
