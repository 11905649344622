$(document).ready(function () {
    $('#cart > button').click(function () {
        $('body').addClass('cart-is-open');
        $('.minicart-body').addClass('open');

    });
    $('.close-mini-cart-btn').click(function () {
        $('body').removeClass('cart-is-open');
        $('.minicart-body').removeClass('open');
    });
    $('.body-cover').click(function () {
        $('.minicart-body').removeClass('open');
        $('.megamenu-style-dev').removeClass('active');
        $('.megamenu-wrapper').removeClass('so-megamenu-active');
        $('body').removeClass('cart-is-open').removeClass('menu-is-open');
        $('.burger-menu').removeClass('active');
    });
    $(document).on('keydown', function(event) {
        if (event.key == "Escape") {
            $('.minicart-body').removeClass('open');
            $('body').removeClass('cart-is-open').removeClass('menu-is-open');
        }
    });
});