// import Swiper JS
import Swiper, { Autoplay, Navigation, EffectFade, Pagination } from 'swiper';
// import Swiper styles
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';

window.addEventListener('DOMContentLoaded', () => {
    // homepage slider
    const homepageHero = new Swiper('.hero-slider', {
        modules: [Autoplay, EffectFade],
        loop: true,
        autoplay: {
            delay: 5000,
        },
        effect: 'fade',
        fadeEffect: {
            crossFade: true,
        },
    });

    const reviews = new Swiper('.reviews', {
        modules: [Navigation, Pagination],
        pagination: {
            el: '.swiper-pagination',
            type: 'fraction',
        },
        navigation: {
            nextEl: '.reviews-button.next',
            prevEl: '.reviews-button.prev',
        },
    });

    const bestSellers = new Swiper('.bestSellers', {
        modules: [Pagination, Autoplay],
        slidesPerView: 1,
        spaceBetween: 5,
        loop: true,
        autoplay: {
            delay: 5000,
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        breakpoints: {
            422: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            992: {
                loop: false,
                slidesPerView: 3,
                spaceBetween: 30,
            },
            1440: {
                loop: false,
                slidesPerView: 3,
                spaceBetween: 45,
            },
        },
    });

    const specialEdition = new Swiper('.specialEdition', {
        modules: [Pagination, Autoplay],
        slidesPerView: 1,
        spaceBetween: 5,
        loop: true,
        autoplay: {
            delay: 5000,
        },
        pagination: {
            el: '.swiper-pagination.special-edition-pagination',
            clickable: true,
        },
        breakpoints: {
            422: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
        },
    });


    const homeBanners = new Swiper('.homeBanners', {
        modules: [Autoplay],
        slidesPerView: 1,
        spaceBetween: 5,
        loop: true,
        autoplay: {
            delay: 5000,
        },
    });



    if (document.querySelector('.specialEdition')) {
        if (window.innerWidth < 992) {
            specialEdition.init();
        } else {
            specialEdition.destroy(false);
            console.log(window.innerWidth);
        }

        window.addEventListener('resize', () => {
            if (window.innerWidth  < 992) {
                specialEdition.init();
            } else {
                specialEdition.destroy(false);
            }
        });
    }

    if (document.querySelector('.homeBanners')) {
        if (window.innerWidth < 661) {
            homeBanners.init();
        } else {
            homeBanners.destroy(false);
            console.log(window.innerWidth);
        }

        window.addEventListener('resize', () => {
            if (window.innerWidth  < 661) {
                homeBanners.init();
            } else {
                homeBanners.destroy(false);
            }
        });
    }

    const productThumbnail = new Swiper('.product-thumbnails-slider', {
        modules: [Pagination],
        pagination: {
            el: '.product-thumbs-pagination',
            clickable: true,
        },
    });

    const relatedProducts = new Swiper('.related-products-slider', {
        modules: [Pagination],
        slidesPerView: 3,
        spaceBetween: 5,
        loop: true,
        pagination: {
            el: '.product-related-pagination',
            clickable: true,
        },
        breakpoints: {
            422: {
                slidesPerView: 2,
                spaceBetween: 20,
                pagination: {
                    el: '.product-related-pagination',
                    clickable: true,
                },
            },
            992: {
                slidesPerView: 3,
                spaceBetween: 30,
                pagination: false,
            },
            1280: {
                loop: false,
                slidesPerView: 3,
                spaceBetween: 90,
                pagination: false,
            },
        },
    });
    if (document.querySelector('.related-products-slider')) {
        if (window.innerWidth > 768) {
            relatedProducts.init();
        } else {
            relatedProducts.destroy(false);
        }

        window.addEventListener('resize', () => {
            if (window.innerWidth > 768) {
                relatedProducts.init();
            } else {
                relatedProducts.destroy(false);
            }
        });
    }
});
