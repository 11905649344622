/* 
 run this in cli
 npm i gsap animejs 
 and add to your reset styles
----start----
 .fade-in {
    opacity: 0;
}

.fade-children {
    > * {
        opacity: 0;
    }
}

.typing-text {
    opacity: 0;
    animation: fadeIn 0.01s 0.3s forwards;
    .letter {
        opacity: 0;
    }
}
----end----
*/

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger.js';
import anime from 'animejs/lib/anime.es.js';

gsap.registerPlugin(ScrollTrigger);

window.addEventListener('DOMContentLoaded', () => {
    // fade in animation
    const fadeIn = document.querySelectorAll('.fade-in');
    if (fadeIn.length) {
        fadeIn.forEach((el) => {
            const fadeTl = gsap.timeline({
                scrollTrigger: {
                    trigger: el,
                    start: 'top 65%',},
            });
            fadeTl.fromTo(el, { yPercent: 5, opacity: 0 }, { yPercent: 0, opacity: 1, duration: 1, ease: 'ease' });
        });
    }

    const fadeInHeaders = document.querySelectorAll('.fade-in-headers');
    if (fadeInHeaders.length) {
        fadeInHeaders.forEach((el) => {
            const fadeTlHeaders = gsap.timeline({
                scrollTrigger: {
                    trigger: el,
                    start: 'top 65%',},
            });
            fadeTlHeaders.to(el, { top: 0, opacity: 1, duration: 0.5, ease: 'ease' });
        });
    }

    // fade in children elements animation
    const fadeChildren = document.querySelectorAll('.fade-children');
    if (fadeChildren.length) {
        fadeChildren.forEach((el) => {
            const showTl = gsap.timeline({
                scrollTrigger: {
                    trigger: el,
                    start: 'top 65%',
                },
            });
            showTl.fromTo(el.children, { y: 20, opacity: 0 }, { y: 0, opacity: 1, duration: 1, stagger: 0.3, ease: 'ease' });
        });
    }

    // spin clockwise animation
    const spinClockwise = document.querySelectorAll('.spin-clockwise');
    if (spinClockwise.length) {
        spinClockwise.forEach((el) => {
            const spinTl = gsap.timeline({
                scrollTrigger: {
                    trigger: 'body',
                    start: 'top top',
                    scrub: true,
                },
            });
            spinTl.fromTo(el, { rotation: 0 }, { transformOrigin: 'center', rotation: 360 * 2, ease: 'ease' }); // 360 * x (multiply by x to make it faster)
        });
    }

    // spin counter clockwise animation
    const spinCounterClockwise = document.querySelectorAll('.spin-counter-clockwise');
    if (spinCounterClockwise.length) {
        spinCounterClockwise.forEach((el) => {
            const spinTl = gsap.timeline({
                scrollTrigger: {
                    trigger: 'body',
                    start: 'top top',
                    scrub: true,
                },
            });
            spinTl.fromTo(el, { rotation: 0 }, { transformOrigin: 'center', rotation: -360, ease: 'ease' });
        });
    }

    // fade in children elements animation (faster)
    const fadeChildrenFast = document.querySelectorAll('.fade-children-fast');
    if (fadeChildrenFast.length) {
        fadeChildrenFast.forEach((el) => {
            const showTl = gsap.timeline({
                scrollTrigger: {
                    trigger: el,
                    start: 'top 65%',
                },
            });
            showTl.fromTo(el.children, { y: 10, opacity: 0 }, { y: 0, opacity: 1, duration: 0.8, stagger: 0.1, ease: 'ease' });
        });
    }

    // Typing animation
    const textWrapper = document.querySelectorAll('.typing-text');
    if (textWrapper.length) {
        textWrapper.forEach((el) => {
            el.innerHTML = el.textContent.replace(/\S/g, "<span class='letter'>$&</span>"); // replace all non-space characters with span filled with the same character

            const revealLetters = gsap.timeline({
                scrollTrigger: {
                    trigger: el,
                    start: 'top 60%',
                    once: true, // only trigger once
                    onEnter: () => {
                        anime.timeline().add({
                            targets: el.children, // target all element children (each letter)
                            opacity: [0, 1],
                            easing: 'easeInOutQuad',
                            duration: 1250,
                            delay: (el, i) => 100 * (i + 1),
                        });
                    },
                },
            });
        });
    }
});
