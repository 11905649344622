import './style.scss';

import './scripts/footer';
import './scripts/minicart';
import './scripts/filterButtons';
import './scripts/checkout';
import './scripts/sliders';
import './scripts/menu';

import './scripts/animations';
